
import { User } from "@/store/user/types";
import { Program } from "@/types";
import { Component, Vue } from "vue-property-decorator";
import { ROOT_ACTIONS } from "@/store/actions";
import { USER_GETTERS } from "@/store/user/getters";
import { collection, documentId, getDocs, query, where } from "firebase/firestore";
import { firestore } from "@/firebase";

@Component({
  components: {
    StateTypeSpan: () => import("@/components/typography/StateTypeSpan.vue"),
  },
})
export default class Home extends Vue {
  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  programs = [] as Program[];

  participants: Record<string, User> = {};

  loading = true;
  percentLoaded = 0;

  query = '';

  get filteredPrograms() {
    return this.programs.filter((program) => {
      return this.participants[program.participant].firstname.toLowerCase().includes(this.query.toLowerCase()) ||
        this.participants[program.participant].lastname.toLowerCase().includes(this.query.toLowerCase())
    })
  }

  async mounted() {
    try {
      this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Home', target: '/' }])
      const program = await getDocs(query(collection(firestore, 'programs'), where('coach', '==', this.user.id)))
      const users = await getDocs(query(collection(firestore, 'users')))

      users.forEach((doc) => {
        this.participants[doc.id] = {
          ...doc.data() as User,
          id: doc.id,
        }
      })

      // sort by user firstname
      let p: Program[] = [];

      program.forEach((doc) => {
        if(!this.participants[doc.data().participant]) return;
        p.push({
          ...doc.data() as Program,
          id: doc.id,
          title: `${this.participants[doc.data().participant].firstname} ${this.participants[doc.data().participant].lastname}`
        })
      })
      this.programs = p.sort((a, b) => {
        return a.title.localeCompare(b.title)
      })
    } catch (error) {
      console.error(error)
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: 'Error'
      })
    } finally {
      this.loading = false;
    }

  }
}
